<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <b-img
          v-if="!($store.state.auth.configData !== '' && $store.state.auth.configData.name !== 'BeOnTrips' && !imageError)"
          :src="$store.state.auth.configData.clubLogo"
          alt="logo"
          width="180"
          @error="noImage"
        />
        <Logo v-else/> -->
        <Logo />

        <!-- <vuexy-logo /> -->
        <!-- <h2
          class="brand-text text-primary mb-0"
          style="line-height: 1.6; margin-left:5px"
        >
          {{ name }}
        </h2> -->
      </b-link>

      <!-- /Brand logo

       Left Text-->

      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to {{ name }}
          </b-card-title>
          <b-card-text class="mb-2 welcomeText">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group
                class="emailLabel"
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form.email"
                    class="email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                style="font-size: 0.857rem"
                class="label"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="d-flex justify-content-between mb-2">
                <b-link :to="{ name: 'forgot-password' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div>

              <!-- <validation-provider
                #default="{ errors }"
                rules="required"
                name="recaptcha"
              >
                <vue-recaptcha
                  v-model="form.recaptcha"
                  :sitekey="form.siteKey"
                  @verify="mxVerify"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->

              <!-- submit buttons -->
              <b-button
                class="signinBtn mt-2"
                :disabled="loading"
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Sign in
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>
            </b-form>
          </validation-observer>
          <p style="width:max-content" class="clearfix mb-0">
            <span class="float-md-left d-block d-md-inline-block mt-2">
              COPYRIGHT © {{ new Date().getFullYear() }}
              <b-link class="ml-25">{{ name }}</b-link>
              <span class="d-none d-sm-inline-block">, All Rights Reserved</span>
            </span>
          </p>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
	BRow,
	BCol,
	BLink,
	BFormGroup,
	BFormInput,
	BInputGroupAppend,
	BInputGroup,
	BCardText,
	BCardTitle,
	BImg,
	BForm,
	BButton
} from "bootstrap-vue";
import { required, email } from "@core/utils/validations/validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";
import { serverMsg, name } from "@/helpers/constant";
import VueRecaptcha from "vue-recaptcha";
import Logo from "@/layouts/frontend-logo/Logo.vue";
// import VuexyLogo from "@core/layouts/components/Logo.vue";

export default {
	components: {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BCardText,
		BCardTitle,
		BImg,
		BForm,
		BButton,
		Logo,
		// VuexyLogo,
		ValidationProvider,
		ValidationObserver,
		VueRecaptcha
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			form: {
				email: "",
				password: "",
				siteKey: "6Lf1nX8cAAAAAJuEKzAzcbOWlYYKITrHaZjuBVPY",
        // siteKey: "6LcQLykeAAAAAAXR1PJe6FQ0c81sdGOWHbSVHdNI",// Live Site Key app.beontrips.hu
				recaptcha: null
			},
			status: "",
			sideImg: require("@/assets/images/pages/login-v2.svg"),
			// validation rulesimport store from '@/store/index'
			required,
			email,
			loading: false,
			disabled: true,
      imageError: false,
      name: (this.$store.state.auth?.configData && this.$store.state.auth?.configData?.name) ? this.$store.state.auth?.configData?.name : 'Beontrips'
		};
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
		},
		imgUrl() {
			if (store.state.appConfig.layout.skin === "dark") {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
				return this.sideImg;
			}
			return this.sideImg;
		}
	},
	methods: {
		...mapActions({
			signIn: "auth/signIn"
		}),

		mxVerify(response) {
			this.form.recaptcha = response;
		},

		validationForm() {
			this.$refs.loginValidation.validate().then(success => {
				if (success) {
					this.loading = true;
					this.signIn(this.form)
						.then(() => {
							this.loading = false;
							window.location = "/dashboard";
							// this.$router.push("/dashboard");
							this.$toast({
								component: ToastificationContent,
								props: {
									title: "You have successfully logged in",
									icon: "CheckIcon",
									variant: "success"
								}
							});
						})
						.catch(error => {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: error.response
										? error.response.data.message
										: serverMsg,
									icon: "X-CircleIcon",
									variant: "danger"
								}
							});
							setTimeout(() => {
								this.loading = false;
							}, 3000);
						});
				}
			});
		},
    noImage() {
			this.imageError = true
		}
	}
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
